import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import { containerStyles } from '../styles/layout';
import { media } from '../theme/media';
import { rem } from '../utilities/style';

import Header from '../components/blocks/Header';
import Layout from '../components/Layout';
import Card from '../components/Card';
import FlexibleBlock from '../components/FlexibleBlock';
import Seo from '../components/Seo';

const ContentWrapper = styled.div`

`;

const Container = styled.div`
  ${containerStyles.regular}
  margin-top: ${rem(50)};
`;

const Grid = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  gap: ${rem(40)};
  margin-bottom: ${rem(100)};

  ${media('>small')} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: ${0};
  }
`;

const StyledCard = styled(Card)`

  ${media('>small')} {
    &:nth-of-type(even) {
      margin-top: ${rem(40)};
    }
  }
`;

const WorkPage = ({ data }) => {
  const works = data.allSanityWork.nodes;
  const { blocks } = data.sanityListingPage;

  return (
    <Layout>
      <Header headline="The Goods" />
      <ContentWrapper>
        <Container>
          <Grid>
            {works.map(({
              rollupImage,
              headline,
              subheadline,
              slug,
            }, index) => (
              <StyledCard
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                image={rollupImage.asset.gatsbyImageData}
                subheadline={subheadline}
                headline={headline}
                link={`/work/${slug.current}`}
              />
            ))}
          </Grid>
        </Container>
      </ContentWrapper>
      {blocks.map((block, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FlexibleBlock key={index} type={block._type} data={block} />
      ))}
    </Layout>
  );
};

export const Head = ({ data }) => {
  const { seo } = data.sanityListingPage;
  return (
    <Seo
      title={seo?.title}
      description={seo?.description}
      openGraphTitle={seo?.openGraphTitle || seo?.title}
      openGraphDescription={seo?.openGraphDescription || seo?.description}
      openGraphImage={seo?.openGraphImage?.asset.url}
      noFollowNoIndex={seo?.noFollowNoIndex}
    />
  );
};

export const query = graphql`
query allWork {
  allSanityWork(
  sort: {fields: [order] order: ASC}
  filter: {active: {eq: true}}
) {
    nodes {
      headline
      subheadline
      slug {
        current
      }
      rollupImage {
        asset {
          altText
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }

  sanityListingPage(_id: {eq: "workListingPage"}) {
    blocks {
      ...FlexibleBlockFragment
    }
    seo {
      ...SeoFragment
    }
  }
}
`;

export default WorkPage;
